import React from "react";
import Header from "../components/header";
import Layout, { PageContents } from "../components/layout";
import Seo from "../components/seo";
import { SITE_TITLE_APPEND } from "../constants";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <main>
        <Seo title={`Not found ${SITE_TITLE_APPEND}`} />
        <Header />

        <PageContents>
          <h1 className="h5">Not found</h1>

          <p className="my-5">
            The page you are looking is not available in our site.
          </p>
        </PageContents>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
